import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/common/seo"
import Header from "../components/ui/header"
import styled from "styled-components"
import vars from "../styles/vars"
import Button from "../components/common/button"
import { motion } from "framer-motion"

const PostTemplate = ({ data }) => {
  const { wordpressPost: post } = data

  // TODO: Look at better ways to handle the below.
  const renderComponents =
    post.acf.modules_post !== null && post.acf.modules_post.length > 0
      ? true
      : false

  const animationVariants = {
    initial: { opacity: 0, y: 50 },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: 50,
    },
  }

  return (
    <>
      <SEO yoast={post.yoast_meta} />
      <Header />
      <motion.div className="container mt-3" variants={animationVariants}>
        <div className="row">
          <div className="col-12 mb-3">
            <Link to="/blog/" style={{ color: vars.black }}>
              &#60; Back
            </Link>
          </div>
          <div className="col-12 col-lg-6 pr-3 pr-lg-4">
            <BlogTitle className="mt-0 mb-1">{post.title}</BlogTitle>
            <strong>{post.date}</strong>
            {renderComponents &&
              post.acf.modules_post.map(item => {
                switch (item.__typename) {
                  case "WordPressAcf_mod__hero_banner":
                    return (
                      <img
                        key={`WordPressAcf_mod__hero_banner-${post.slug}`}
                        src={item.mod__hero_banner_image}
                        alt={post.title}
                        className="w-100 mt-4 mb-3 mb-lg-4"
                      />
                    )
                  default:
                    return null
                }
              })}
            {!renderComponents && (
              <img
                src="https://placehold.it/600x400?text=Image%20Coming%20Soon"
                alt={post.title}
                className="w-100 mt-4 mb-3 mb-lg-4"
              />
            )}
            <Button to="/blog/" className="d-none d-lg-inline-block">
              Back to blog
            </Button>
          </div>
          <BlogContent className="col-12 col-lg-6 pl-3 pl-lg-4">
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
            <div className="mt-4">
              <Button to="/blog/" className="d-none d-lg-inline-block">
                Back to blog
              </Button>
            </div>
          </BlogContent>
        </div>
      </motion.div>
    </>
  )
}

export default PostTemplate

export const query = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      content
      slug
      excerpt
      date(formatString: "DD/MM/YYYY")
      acf {
        modules_post {
          __typename
          mod__hero_banner_image
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
  }
`

const BlogTitle = styled.h1`
  color: ${vars.primaryColour};
`

const BlogContent = styled.div`
  border-left: 1px solid ${vars.grey};
  @media (max-width: ${vars.screen_md_max}) {
    border: none;
  }

  figure {
    margin-left: 0;
    margin-right: 0;
  }

  img {
    width: 100%;
  }
`
